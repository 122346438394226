// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Common
export const SHOW_ERROR = 'SHOW_ERROR';

// Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const GET_OSS_TOKEN = 'GET_OSS_TOKEN';
export const GET_OSS_TOKEN_SUCCESS = 'GET_OSS_TOKEN_SUCCESS';
export const RECEIVED_NOTIFICATION = 'RECEIVED_NOTIFICATION';

// Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

// Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

// Users
export const RESET_USER = 'RESET_USER';
export const OPEN_USER_MODAL = 'OPEN_USER_MODAL';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_USERS_ABORT = 'FETCH_USERS_ABORT';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

// Members
export const RESET_MEMBER = 'RESET_MEMBER';
export const OPEN_MEMBER_MODAL = 'OPEN_MEMBER_MODAL';
export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_ERROR = 'FETCH_MEMBERS_ERROR';
export const FETCH_MEMBERS_ABORT = 'FETCH_MEMBERS_ABORT';
export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_ERROR = 'ADD_MEMBER_ERROR';
export const EDIT_MEMBER = 'EDIT_MEMBER';
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
export const EDIT_MEMBER_ERROR = 'EDIT_MEMBER_ERROR';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_ERROR = 'UPDATE_MEMBER_ERROR';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';
export const RESET_MEMBER_PASSES = 'RESET_MEMBER_PASSES';
export const OPEN_MEMBER_PASSES_MODAL = 'OPEN_MEMBER_PASSES_MODAL';
export const EDIT_MEMBER_PASSES = 'EDIT_MEMBER_PASSES';
export const EDIT_MEMBER_PASSES_SUCCESS = 'EDIT_MEMBER_PASSES_SUCCESS';
export const EDIT_MEMBER_PASSES_ERROR = 'EDIT_MEMBER_PASSES_ERROR';
export const UPDATE_MEMBER_TICKET = 'UPDATE_MEMBER_TICKET';
export const UPDATE_MEMBER_TICKET_SUCCESS = 'UPDATE_MEMBER_TICKET_SUCCESS';
export const UPDATE_MEMBER_TICKET_ERROR = 'UPDATE_MEMBER_TICKET_ERROR';

// Participants
export const RESET_PARTICIPANT = 'RESET_PARTICIPANT';
export const OPEN_PARTICIPANT_MODAL = 'OPEN_PARTICIPANT_MODAL';
export const FETCH_PARTICIPANTS = 'FETCH_PARTICIPANTS';
export const FETCH_PARTICIPANTS_SUCCESS = 'FETCH_PARTICIPANTS_SUCCESS';
export const FETCH_PARTICIPANTS_ERROR = 'FETCH_PARTICIPANTS_ERROR';
export const FETCH_PARTICIPANTS_ABORT = 'FETCH_PARTICIPANTS_ABORT';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const ADD_PARTICIPANT_SUCCESS = 'ADD_PARTICIPANT_SUCCESS';
export const ADD_PARTICIPANT_ERROR = 'ADD_PARTICIPANT_ERROR';
export const EDIT_PARTICIPANT = 'EDIT_PARTICIPANT';
export const EDIT_PARTICIPANT_SUCCESS = 'EDIT_PARTICIPANT_SUCCESS';
export const EDIT_PARTICIPANT_ERROR = 'EDIT_PARTICIPANT_ERROR';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT_ERROR = 'UPDATE_PARTICIPANT_ERROR';
export const DELETE_PARTICIPANT = 'DELETE_PARTICIPANT';
export const DELETE_PARTICIPANT_SUCCESS = 'DELETE_PARTICIPANT_SUCCESS';
export const DELETE_PARTICIPANT_ERROR = 'DELETE_PARTICIPANT_ERROR';

// Merchandises
export const RESET_MERCHANDISE = 'RESET_MERCHANDISE';
export const OPEN_MERCHANDISE_MODAL = 'OPEN_MERCHANDISE_MODAL';
export const IMPORT_MERCHANDISES = 'IMPORT_MERCHANDISES';
export const IMPORT_MERCHANDISES_SUCCESS = 'IMPORT_MERCHANDISES_SUCCESS';
export const IMPORT_MERCHANDISES_ERROR = 'IMPORT_MERCHANDISES_ERROR';
export const FETCH_MERCHANDISES = 'FETCH_MERCHANDISES';
export const FETCH_MERCHANDISES_SUCCESS = 'FETCH_MERCHANDISES_SUCCESS';
export const FETCH_MERCHANDISES_ERROR = 'FETCH_MERCHANDISES_ERROR';
export const FETCH_MERCHANDISES_ABORT = 'FETCH_MERCHANDISES_ABORT';
export const ADD_MERCHANDISE = 'ADD_MERCHANDISE';
export const ADD_MERCHANDISE_SUCCESS = 'ADD_MERCHANDISE_SUCCESS';
export const ADD_MERCHANDISE_ERROR = 'ADD_MERCHANDISE_ERROR';
export const EDIT_MERCHANDISE = 'EDIT_MERCHANDISE';
export const EDIT_MERCHANDISE_SUCCESS = 'EDIT_MERCHANDISE_SUCCESS';
export const EDIT_MERCHANDISE_ERROR = 'EDIT_MERCHANDISE_ERROR';
export const DELETE_MERCHANDISE = 'DELETE_MERCHANDISE';
export const DELETE_MERCHANDISE_SUCCESS = 'DELETE_MERCHANDISE_SUCCESS';
export const DELETE_MERCHANDISE_ERROR = 'DELETE_MERCHANDISE_ERROR';
export const UPDATE_MERCHANDISE = 'UPDATE_MERCHANDISE';
export const UPDATE_MERCHANDISE_SUCCESS = 'UPDATE_MERCHANDISE_SUCCESS';
export const UPDATE_MERCHANDISE_ERROR = 'UPDATE_MERCHANDISE_ERROR';
export const GET_MERCHANDISE_SETUP = 'GET_MERCHANDISE_SETUP';
export const GET_MERCHANDISE_SETUP_SUCCESS = 'GET_MERCHANDISE_SETUP_SUCCESS';
export const GET_MERCHANDISE_SETUP_ERROR = 'GET_MERCHANDISE_SETUP_ERROR';
export const IMPORT_INVENTORY = 'IMPORT_INVENTORY';
export const IMPORT_INVENTORY_SUCCESS = 'IMPORT_INVENTORY_SUCCESS';
export const IMPORT_INVENTORY_ERROR = 'IMPORT_INVENTORY_ERROR';
export const FETCH_INVENTORY_IMPORTS = 'FETCH_INVENTORY_IMPORTS';
export const FETCH_INVENTORY_IMPORTS_SUCCESS = 'FETCH_INVENTORY_IMPORTS_SUCCESS';
export const FETCH_INVENTORY_IMPORTS_ERROR = 'FETCH_INVENTORY_IMPORTS_ERROR';
export const EXPORT_MERCHANDISE = 'EXPORT_MERCHANDISE';
export const EXPORT_MERCHANDISE_SUCCESS = 'EXPORT_MERCHANDISE_SUCCESS';
export const EXPORT_MERCHANDISE_ERROR = 'EXPORT_MERCHANDISE_ERROR';

// Groups
export const RESET_GROUP = 'RESET_GROUP';
export const OPEN_GROUP_MODAL = 'OPEN_GROUP_MODAL';
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR';
export const FETCH_GROUPS_ABORT = 'FETCH_GROUPS_ABORT';
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_ERROR = 'ADD_GROUP_ERROR';
export const EDIT_GROUP = 'EDIT_GROUP';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_ERROR = 'EDIT_GROUP_ERROR';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';
export const GET_GROUP_SETUP = 'GET_GROUP_SETUP';
export const GET_GROUP_SETUP_SUCCESS = 'GET_GROUP_SETUP_SUCCESS';
export const GET_GROUP_SETUP_ERROR = 'GET_GROUP_SETUP_ERROR';

// Passes
export const RESET_PASS = 'RESET_PASS';
export const OPEN_PASS_MODAL = 'OPEN_PASS_MODAL';
export const FETCH_PASSES = 'FETCH_PASSES';
export const FETCH_PASSES_SUCCESS = 'FETCH_PASS_SUCCESS';
export const FETCH_PASSES_ERROR = 'FETCH_PASSES_ERROR';
export const FETCH_PASSES_ABORT = 'FETCH_PASSES_ABORT';
export const ADD_PASS = 'ADD_PASS';
export const ADD_PASS_SUCCESS = 'ADD_PASS_SUCCESS';
export const ADD_PASS_ERROR = 'ADD_PASS_ERROR';
export const EDIT_PASS = 'EDIT_PASS';
export const EDIT_PASS_SUCCESS = 'EDIT_PASS_SUCCESS';
export const EDIT_PASS_ERROR = 'EDIT_PASS_ERROR';
export const DELETE_PASS = 'DELETE_PASS';
export const DELETE_PASS_SUCCESS = 'DELETE_PASS_SUCCESS';
export const DELETE_PASS_ERROR = 'DELETE_PASS_ERROR';
export const UPDATE_PASS = 'UPDATE_PASS';
export const UPDATE_PASS_SUCCESS = 'UPDATE_PASS_SUCCESS';
export const UPDATE_PASS_ERROR = 'UPDATE_PASS_ERROR';
export const GET_PASS_SETUP = 'GET_PASS_SETUP';
export const GET_PASS_SETUP_SUCCESS = 'GET_PASS_SETUP_SUCCESS';
export const GET_PASS_SETUP_ERROR = 'GET_PASS_SETUP_ERROR';
export const EXPORT_PASS = 'EXPORT_PASS';
export const EXPORT_PASS_SUCCESS = 'EXPORT_PASS_SUCCESS';
export const EXPORT_PASS_ERROR = 'EXPORT_PASS_ERROR';

// Pass Categories
export const FETCH_PASS_CATEGORIES = 'FETCH_PASS_CATEGORIES';
export const FETCH_PASS_CATEGORIES_SUCCESS = 'FETCH_PASS_CATEGORIES_SUCCESS';

// Categories
export const RESET_CATEGORY = 'RESET_CATEGORY';
export const OPEN_CATEGORY_MODAL = 'OPEN_CATEGORY_MODAL';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';
export const FETCH_CATEGORIES_ABORT = 'FETCH_CATEGORIES_ABORT';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

// Workshops
export const FETCH_WORKSHOPS = 'FETCH_WORKSHOPS';
export const FETCH_WORKSHOPS_SUCCESS = 'FETCH_WORKSHOPS_SUCCESS';
export const FETCH_WORKSHOPS_ERROR = 'FETCH_WORKSHOPS_ERROR';
export const EDIT_WORKSHOP = 'EDIT_WORKSHOP';
export const EDIT_WORKSHOP_SUCCESS = 'EDIT_WORKSHOP_SUCCESS';
export const EDIT_WORKSHOP_ERROR = 'EDIT_WORKSHOP_ERROR';
export const ADD_WORKSHOP = 'ADD_WORKSHOP';
export const ADD_WORKSHOP_SUCCESS = 'ADD_WORKSHOP_SUCCESS';
export const ADD_WORKSHOP_ERROR = 'ADD_WORKSHOP_ERROR';
export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const UPDATE_WORKSHOP_SUCCESS = 'UPDATE_WORKSHOP_SUCCESS';
export const UPDATE_WORKSHOP_ERROR = 'UPDATE_WORKSHOP_ERROR';
export const DELETE_WORKSHOP = 'DELETE_WORKSHOP';
export const DELETE_WORKSHOP_SUCCESS = 'DELETE_WORKSHOP_SUCCESS';
export const DELETE_WORKSHOP_ERROR = 'DELETE_WORKSHOP_ERROR';
export const EXPORT_WORKSHOP = 'EXPORT_WORKSHOP';
export const EXPORT_WORKSHOP_SUCCESS = 'EXPORT_WORKSHOP_SUCCESS';
export const EXPORT_WORKSHOP_ERROR = 'EXPORT_WORKSHOP_ERROR';
export const RESET_WORKSHOP = 'RESET_WORKSHOP';
export const OPEN_WORKSHOP_MODAL = 'OPEN_WORKSHOP_MODAL';
export const OPEN_COMPETITION_MODAL = 'OPEN_COMPETITION_MODAL';
export const EXPORT_PARTICIPANT = 'EXPORT_PARTICIPANT';
export const EXPORT_PARTICIPANT_SUCCESS = 'EXPORT_PARTICIPANT_SUCCESS';
export const EXPORT_PARTICIPANT_ERROR = 'EXPORT_PARTICIPANT_ERROR';

// Orders
export const RESET_ORDER = 'RESET_ORDER';
export const OPEN_ORDER = 'OPEN_ORDER';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const FETCH_ORDERS_ABORT = 'FETCH_ORDERS_ABORT';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_ERROR = 'ADD_ORDER_ERROR';
export const PROCESS_ORDER = 'PROCESS_ORDER';
export const PROCESS_ORDER_SUCCESS = 'PROCESS_ORDER_SUCCESS';
export const PROCESS_ORDER_ERROR = 'PROCESS_ORDER_ERROR';
export const SHIP_ORDER = 'SHIP_ORDER';
export const SHIP_ORDER_SUCCESS = 'SHIP_ORDER_SUCCESS';
export const SHIP_ORDER_ERROR = 'SHIP_ORDER_ERROR';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
export const REFUND_ORDER = 'REFUND_ORDER';
export const REFUND_ORDER_SUCCESS = 'REFUND_ORDER_SUCCESS';
export const REFUND_ORDER_ERROR = 'REFUND_ORDER_ERROR';
export const ADD_ORDER_NOTE = 'ADD_ORDER_NOTE';
export const ADD_ORDER_NOTE_SUCCESS = 'ADD_ORDER_NOTE_SUCCESS';
export const ADD_ORDER_NOTE_ERROR = 'ADD_ORDER_NOTE_ERROR';
export const EXPORT_ORDER_REPORT = 'EXPORT_ORDER_REPORT';
export const EXPORT_ORDER_REPORT_SUCCESS = 'EXPORT_ORDER_REPORT_SUCCESS';
export const EXPORT_ORDER_REPORT_ERROR = 'EXPORT_ORDER_REPORT_ERROR';
export const EXPORT_DAILY_REPORT = 'EXPORT_DAILY_REPORT';
export const EXPORT_DAILY_REPORT_SUCCESS = 'EXPORT_DAILY_REPORT_SUCCESS';
export const EXPORT_DAILY_REPORT_ERROR = 'EXPORT_DAILY_REPORT_ERROR';
export const RESEND_INVOICE = 'RESEND_INVOICE';
export const RESEND_INVOICE_SUCCESS = 'RESEND_INVOICE_SUCCESS';
export const RESEND_INVOICE_ERROR = 'RESEND_INVOICE_ERROR';
export const CHANGE_PAYMENT_METHODS = 'CHANGE_PAYMENT_METHODS';
export const CHANGE_PAYMENT_METHODS_SUCCESS = 'CHANGE_PAYMENT_METHODS_SUCCESS';
export const CHANGE_PAYMENT_METHODS_ERROR = 'CHANGE_PAYMENT_METHODS_ERROR';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';
export const UPDATE_TRANS_ID = 'UPDATE_TRANS_ID';
export const UPDATE_TRANS_ID_SUCCESS = 'UPDATE_TRANS_ID_SUCCESS';
export const UPDATE_TRANS_ID_ERROR = 'UPDATE_TRANS_ID_ERROR';

// Sales
export const FETCH_SALES = 'FETCH_SALES';
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
export const FETCH_SALES_ERROR = 'FETCH_SALES_ERROR';
export const FETCH_SALES_ABORT = 'FETCH_SALES_ABORT';
export const FETCH_SALES_REPORT = 'FETCH_SALES_REPORT';
export const FETCH_SALES_REPORT_SUCCESS = 'FETCH_SALES_REPORT_SUCCESS';
export const FETCH_SALES_REPORT_ERROR = 'FETCH_SALES_REPORT_ERROR';
export const FETCH_SALES_REPORT_ABORT = 'FETCH_SALES_REPORT_ABORT';

// Ticket
export const CLEAR_TICKET = 'CLEAR_TICKET';
export const GET_TICKET = 'GET_TICKET';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const GET_TICKET_ERROR = 'GET_TICKET_ERROR';
export const FREEZE_TICKET = 'FREEZE_TICKET';
export const FREEZE_TICKET_SUCCESS = 'FREEZE_TICKET_SUCCESS';
export const FREEZE_TICKET_ERROR = 'FREEZE_TICKET_ERROR';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_ERROR = 'FETCH_TICKETS_ERROR';
export const RETRIEVE_PHOTO = 'RETRIEVE_PHOTO';
export const RETRIEVE_PHOTO_SUCCESS = 'RETRIEVE_PHOTO_SUCCESS';
export const RETRIEVE_PHOTO_ERROR = 'RETRIEVE_PHOTO_ERROR';
export const CLEAR_PHOTO = 'CLEAR_PHOTO';

// Banners
export const RESET_BANNER = 'RESET_BANNER';
export const OPEN_BANNER_MODAL = 'OPEN_BANNER_MODAL';
export const FETCH_BANNERS = 'FETCH_BANNERS';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_ERROR = 'FETCH_BANNERS_ERROR';
export const FETCH_BANNERS_ABORT = 'FETCH_BANNERS_ABORT';
export const ADD_BANNER = 'ADD_BANNER';
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const ADD_BANNER_ERROR = 'ADD_BANNER_ERROR';
export const EDIT_BANNER = 'EDIT_BANNER';
export const EDIT_BANNER_SUCCESS = 'EDIT_BANNER_SUCCESS';
export const EDIT_BANNER_ERROR = 'EDIT_BANNER_ERROR';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_ERROR = 'UPDATE_BANNER_ERROR';
export const DELETE_BANNER = 'DELETE_BANNER';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR';
export const UPDATE_BANNER_ORDERS = 'UPDATE_BANNER_ORDERS';
export const UPDATE_BANNER_ORDERS_SUCCESS = 'UPDATE_BANNER_ORDERS_SUCCESS';
export const UPDATE_BANNER_ORDERS_ERROR = 'UPDATE_BANNER_ORDERS_ERROR';

// Coupons
export const RESET_COUPON = 'RESET_COUPON';
export const OPEN_COUPON_MODAL = 'OPEN_COUPON_MODAL';
export const FETCH_COUPONS = 'FETCH_COUPONS';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_ERROR = 'FETCH_COUPONS_ERROR';
export const ADD_COUPON = 'ADD_COUPON';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_ERROR = 'ADD_COUPON_ERROR';
export const RETIRE_COUPON = 'RETIRE_COUPON';
export const RETIRE_COUPON_SUCCESS = 'RETIRE_COUPON_SUCCESS';
export const RETIRE_COUPON_ERROR = 'RETIRE_COUPON_ERROR';

// Gyms
export const RESET_GYM = 'RESET_GYM';
export const OPEN_GYM_MODAL = 'OPEN_GYM_MODAL';
export const FETCH_GYMS = 'FETCH_GYMS';
export const FETCH_GYMS_SUCCESS = 'FETCH_GYMS_SUCCESS';
export const FETCH_GYMS_ERROR = 'FETCH_GYMS_ERROR';
export const FETCH_GYMS_ABORT = 'FETCH_GYMS_ABORT';
export const ADD_GYM = 'ADD_GYM';
export const ADD_GYM_SUCCESS = 'ADD_GYM_SUCCESS';
export const ADD_GYM_ERROR = 'ADD_GYM_ERROR';
export const EDIT_GYM = 'EDIT_GYM';
export const EDIT_GYM_SUCCESS = 'EDIT_GYM_SUCCESS';
export const EDIT_GYM_ERROR = 'EDIT_GYM_ERROR';
export const UPDATE_GYM = 'UPDATE_GYM';
export const UPDATE_GYM_SUCCESS = 'UPDATE_GYM_SUCCESS';
export const UPDATE_GYM_ERROR = 'UPDATE_GYM_ERROR';
export const DELETE_GYM = 'DELETE_GYM';
export const DELETE_GYM_SUCCESS = 'DELETE_GYM_SUCCESS';
export const DELETE_GYM_ERROR = 'DELETE_GYM_ERROR';

// Locations
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';

// Attendance
export const FETCH_ATTENDANCE = 'FETCH_ATTENDANCE';
export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS';
export const FETCH_ATTENDANCE_ERROR = 'FETCH_ATTENDANCE_ERROR';
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE';
export const UPDATE_ATTENDANCE_SUCCESS = 'UPDATE_ATTENDANCE_SUCCESS';
export const UPDATE_ATTENDANCE_ERROR = 'UPDATE_ATTENDANCE_ERROR';
export const EXPORT_ATTENDANCE = 'EXPORT_ATTENDANCE';
export const EXPORT_ATTENDANCE_SUCCESS = 'EXPORT_ATTENDANCE_SUCCESS';
export const EXPORT_ATTENDANCE_ERROR = 'EXPORT_ATTENDANCE_ERROR';
export const EDIT_ATTENDANCE = 'EDIT_ATTENDANCE';
export const EDIT_ATTENDANCE_SUCCESS = 'EDIT_ATTENDANCE_SUCCESS';
export const EDIT_ATTENDANCE_ERROR = 'EDIT_ATTENDANCE_ERROR';
export const RESET_ATTENDANCE = 'RESET_ATTENDANCE';
export const OPEN_ATTENDANCE_MODAL = 'OPEN_ATTENDANCE_MODAL';
