import {
  CLEAR_TICKET,
  GET_TICKET,
  GET_TICKET_SUCCESS,
  GET_TICKET_ERROR,
  FREEZE_TICKET,
  FREEZE_TICKET_SUCCESS,
  FREEZE_TICKET_ERROR,
  FETCH_TICKETS,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_ERROR,
  RETRIEVE_PHOTO,
  RETRIEVE_PHOTO_SUCCESS,
  RETRIEVE_PHOTO_ERROR,
  CLEAR_PHOTO
} from '../../constants/ActionTypes';
import { message } from 'antd';

const INIT_STATE = {
  pending: false,
  selected_ticket: null,
  ticket_list: null,
  photo: null
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case CLEAR_TICKET:
      return { ...state, selected_ticket: null }

    case GET_TICKET:
      return { ...state, pending: true }

    case GET_TICKET_SUCCESS:
      return { ...state, selected_ticket: payload, pending: false }

    case GET_TICKET_ERROR:
      message.error(payload);
      return { ...state, selected_ticket: null, pending: false }

    case FREEZE_TICKET:
      return { ...state, pending: true }

    case FREEZE_TICKET_SUCCESS:
      return { ...state, selected_ticket: payload, pending: false }

    case FREEZE_TICKET_ERROR:
      message.error(payload);
      return { ...state, selected_ticket: null, pending: false }

    case FETCH_TICKETS:
      return { ...state, pending: true }

    case FETCH_TICKETS_SUCCESS:
      return { ...state, ticket_list: payload, pending: false }

    case FETCH_TICKETS_ERROR:
      return { ...state, ticket_list: null, pending: false }

    case RETRIEVE_PHOTO:
      return { ...state, pending: true }

    case RETRIEVE_PHOTO_SUCCESS:
      return { ...state, photo: payload, pending: false }

    case RETRIEVE_PHOTO_ERROR:
      message.error(payload);
      return { ...state, photo: null, pending: false }

    case CLEAR_PHOTO:
      return { ...state, photo: null }

    default:
      return state;
  }
}
