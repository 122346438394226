import firebase from 'firebase';
import config from '../config.json';

// Initialize Firebase
firebase.initializeApp(config.firebase);
const auth = firebase.auth();
const messaging = firebase.messaging();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();

const getFCMToken = async () => {
  return await messaging.getToken({ vapidKey: 'BOr0s9pUVInNP6aMLbQlMZTm2Eav1Ua5Q0XKeQXIoyGHRpFqFQzQdAdfg5TbWPykW9voysc4_0rmxMAJ8zD224M' });
}

export {
  database,
  auth,
  messaging,
  getFCMToken,
  googleAuthProvider,
  facebookAuthProvider,
  githubAuthProvider,
  twitterAuthProvider
};
